import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Close from '../../../assets/images/close.svg';
import Loading from '../../../components/Common/Loading';

import { actionSendOTP } from '../../../store/application/actions';

function SendPhoneNumber() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [isMaintance, setIsMaintance] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();


  const onSubmit = async (data) => {
    if (process.env.REACT_APP_MAINTANCE_MODE !== "1") {
      const response = await dispatch(
        actionSendOTP({
          phone: data.phone,
        }),
      );
      if (response?.sts_code === 0) {
        navigate('/application-otp', { state: { phone: data.phone } });
      }
    } else {
      setIsMaintance(true);
    }
  };

  return (
    <>
      {isMaintance && <div className="flex justify-center items-center fixed top-0 left-0 z-[999999] bg-[rgba(0,0,0,0.4)] w-full h-full px-8">
        <div className='w-full text-center bg-white rounded-[38px] h-auto px-7 py-5'>
          <p className="text-[#707070] text-left">為提供更好的貸款服務體驗，KM信貸將進行內部系統升級，即日起暫停私人貸款申請服務，直至另行通知。如有任何查詢，請於辦公時間內致電客戶服務熱線3700 4488。不便之處，敬請原諒。</p>
          <button onClick={() => { navigate('/') }} type="submit" className="rounded-full mt-5 text-white text-sm bg-[#036EB2] px-14 py-2">確定</button>
        </div>
      </div>}
      <Loading isVisible={isSubmitting} />
      <div className="p-10 pt-32 fixed w-full z-10 top-0 left-0 flex items-start justify-center h-full" style={{ backgroundColor: '#0000004d' }}>
        <div className="border-4 w-full border-primary p-4 bg-white" style={{ maxWidth: 414 }}>
          <div className="flex justify-between items-center">
            <p className="flex-1 text-xl text-primary font-semibold border-b-4 border-primary mr-5">申請貸款</p>
            <div className="p-2 pr-0 cursor-pointer" onClick={() => window.location.href = process.env.REACT_APP_HOME_URL}>
              <img src={Close} alt="" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="text-gray mt-4 mb-1">手提電話</p>
            <input
              {...register('phone', { required: true, pattern: /^[4-9][0-9]{7}$/g })}
              value={phone}
              onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ''))}
              className="border border-primary text-primary rounded-md w-full h-10 outline-none px-2"
              style={{ marginBottom: -5 }}
              maxLength={8}
              inputMode={'numeric'}
            />
            {errors.phone && errors.phone.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
            {errors.phone && errors.phone.type === 'pattern' && <span className="text-10 text-red-500">*請輸入正確的手提電話號碼</span>}
            <p className="text-10 mt-2 text-gray">
              我們將會發送一次性驗證碼到閣下輸入之手提電話號碼，
              <span className="text-primary">
                提交前請確認所輸入之手提電話號碼正確無誤。
              </span>
            </p>
            <div className="flex justify-center my-4">
              <button type="submit" disabled={isSubmitting} className={`rounded-full text-white text-sm px-14 py-2 ${isSubmitting ? 'bg-mercury' : 'bg-lightningYellow'}`}>
                確定
              </button>
            </div>
            <p className="text-10 text-gray">
              你申請代表你已詳閱並接受我們的{' '}
              <a href={`${process.env.REACT_APP_HOME_URL}/website-terms/`} className="text-primary underline" target="_blank" rel="noreferrer">
                網站使用條款
              </a>
              {' '}
              及
              {' '}
              <a href={`${process.env.REACT_APP_HOME_URL}/privacy-policy-and-personal-information-collection-statement/`} className="text-primary underline" target="_blank" rel="noreferrer">
                私隱政策及收集個人資料聲明
              </a>
              ，亦同意 KM International (Asia) Co., Ltd. 經電話及短訊通知你有關貸款的服務信息。
            </p>
          </form>
        </div>
      </div>
    </>
  );
}

export default SendPhoneNumber;
